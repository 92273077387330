import { FaLinkedinIn, FaGithub, FaFacebookF } from "react-icons/fa";

type Props = {
  className: string;
};

export default function Socials({ className }: Props) {
  return (
    <div className={className}>
      <a href="https://github.com/awran5" title="Github" aria-label="Github link" target="_blank" rel="noreferrer">
        <FaGithub />
      </a>
      <a
        href="https://www.linkedin.com/in/awran5/"
        title="Linkedin"
        aria-label="Linkedin link"
        target="_blank"
        rel="noreferrer"
      >
        <FaLinkedinIn />
      </a>
      <a
        href="https://web.facebook.com/profile.php?id=100033809550239"
        title="Facebook"
        target="_blank"
        rel="noreferrer"
        aria-label="Facebook link"
      >
        <FaFacebookF />
      </a>
    </div>
  );
}
