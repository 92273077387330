import { useEffect } from "react";
import { ScrollToTop } from "react-simple-scroll-up";
import { FaSun, FaMoon } from "react-icons/fa";
import { About, Skills, Portfolio, Contact, Footer, Header, Home, Quote } from "./components";
import useLocalStorage from "./hooks/useLocalStorage";

const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
const App = () => {
  const [theme, setTheme] = useLocalStorage("theme", defaultDark ? "dark" : "light");

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  return (
    <div className="App">
      <div className="theme-switch" onClick={() => setTheme((prev) => (prev === "light" ? "dark" : "light"))}>
        {theme === "light" ? <FaMoon fontSize={20} /> : <FaSun fill="#e6d01d" fontSize={20} />}
      </div>
      <Header />
      <main>
        <Home />
        <About />
        <Skills />
        <Quote />
        <Portfolio />
        <Contact />
      </main>
      <Footer />
      <ScrollToTop
        size={40}
        strokeWidth={2}
        symbol="&#x21E1;"
        symbolSize={16}
        strokeFillColor="#f4b433"
        strokeEmptyColor="rgb(200 200 200 / 20%)"
      />
    </div>
  );
};

export default App;
