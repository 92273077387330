import { Section } from "../Section";
import ProgressBar from "./ProgressBar";
import "./skills.css";

const skills = [
  {
    skill: "Adobe Photoshop",
    percentage: "80"
  },
  {
    skill: "Adobe Illustrator",
    percentage: "70"
  },
  {
    skill: "JavaScript",
    percentage: "90"
  },
  {
    skill: "TypeScript",
    percentage: "85"
  },
  {
    skill: "WordPress",
    percentage: "85"
  },
  {
    skill: "PHP",
    percentage: "75"
  },
  {
    skill: "MySQL",
    percentage: "70"
  },
  {
    skill: "ReactJS",
    percentage: "90"
  },
  {
    skill: "NodeJS",
    percentage: "80"
  },
  {
    skill: "LAMP/LEMP stack",
    percentage: "80"
  }
];

export function Skills() {
  return (
    <Section id="skills">
      <div className="section-intro text-center">
        <p className="text-light small text-space m-0">The Skills I Have</p>
        <h2 className="text-primary text-space">Skills</h2>
      </div>
      <div className="skills-container">
        <p className="lead">
          I work professionally as a full stack developer, build interesting projects, doing my best to contribute to
          community open source projects. Below is a quick overview of my main technical skill sets and the technologies
          I mostly use.
        </p>
        <div className="grid-3 gap-2 progress-container">
          {skills.map((skill) => (
            <ProgressBar key={skill.skill} text={skill.skill} percentage={skill.percentage} />
          ))}
        </div>
      </div>
    </Section>
  );
}
