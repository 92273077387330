import React from "react";

type Props = {
  text: string;
  percentage: string;
};

export default function ProgressBar({ text, percentage }: Props) {
  return (
    <div className="progress-item">
      <h3>{text}</h3>
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          aria-label={`${text} skill percentage`}
          style={{ width: `${percentage}%` }}
        >
          <span className="shadow">{`${percentage}%`}</span>
        </div>
      </div>
    </div>
  );
}
