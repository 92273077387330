import { FaLink, FaGithub, FaCodepen } from "react-icons/fa";

import IMG1 from "../../assets/projects/react-simple-typewriter.gif";
import IMG2 from "../../assets/projects/egyptsupplier.jpg";
import IMG3 from "../../assets/projects/react-floating-whatsapp.gif";
import IMG4 from "../../assets/projects/eldarderyglass.jpg";
import IMG5 from "../../assets/projects/gkstyle.jpg";
import IMG6 from "../../assets/projects/react-steps-form.gif";
import IMG7 from "../../assets/projects/react-simple-star-rating.gif";
import IMG8 from "../../assets/projects/custom-qr-code.webp";
import IMG9 from "../../assets/projects/react-before-after.gif";
import { Section } from "../Section";
import "./portfolio.css";

const projects = [
  {
    id: 1,
    title: "React Simple Typewriter",
    img: IMG1,
    description: "A simple react component for adding a nice typewriter effect to your project.",
    technologies: "React, Javascript, Typescript",
    link: "https://react-simple-typewriter.vercel.app/",
    github: "https://github.com/awran5/react-simple-typewriter/",
    sandbox: "https://codesandbox.io/s/react-simple-typewriter-uj8ix"
  },
  {
    id: 2,
    title: "Egypt Supplier Website",
    img: IMG2,
    description: "Founded in 2009, Egypt Supplier is specialized in export of fruits & vegetables.",
    technologies: "WordPress, PHP, CSS, Javascript, MySql",
    link: "https://egyptsupplier.com",
    github: "",
    sandbox: ""
  },
  {
    id: 3,
    title: "React Floating Whatsapp",
    img: IMG3,
    description: "Useful app build with react for adding a floating WhatsApp button to your project.",
    technologies: "React, CSS, Typescript",
    link: "",
    github: "https://github.com/awran5/react-floating-whatsapp",
    sandbox: "https://codesandbox.io/s/react-floating-whatsapp-183py"
  },
  {
    id: 4,
    title: "elDarderyGlass Website",
    img: IMG4,
    description: "Fully responsive website build with WordPress and JavaScript.",
    technologies: "WordPress, PHP, JavaScript, CSS, MySql",
    link: "https://eldarderyglass.net",
    github: "",
    sandbox: ""
  },

  {
    id: 5,
    title: "React mui Steps Form",
    img: IMG6,
    description: "React App for Step Form with basic form validation build with React and material UI",
    technologies: "React, Typescript, Material UI",
    link: "https://react-material-ui-step-form.vercel.app/",
    github: "https://github.com/awran5/react-material-ui-step-form",
    sandbox: "https://codesandbox.io/s/react-material-ui-step-form-ui788"
  },
  {
    id: 6,
    title: "GK Style Website",
    img: IMG5,
    description: "Fully responsive interactive website built with Gatsby framework based on React.",
    technologies: "React, Gatsby, CSS",
    link: "https://gkstyle.net/",
    github: "",
    sandbox: ""
  },
  {
    id: 7,
    title: "React Simple Rating",
    img: IMG7,
    description: "A simple react component for adding a star rating to your project.",
    technologies: "React, Typescript",
    link: "https://react-simple-star-rating.vercel.app/",
    github: "https://github.com/awran5/react-simple-star-rating",
    sandbox: "https://codesandbox.io/s/react-simple-rating-ts-fzmpu"
  },
  {
    id: 8,
    title: "React custom QR Code",
    img: IMG8,
    description: "Generate a modern, styled and branded QR Code for Free! Cool app build with React.",
    technologies: "React, Typescript, Bootstrap",
    link: "https://customqr.vercel.app/",
    github: "https://github.com/awran5/custom-qr-code-styling",
    sandbox: ""
  },
  {
    id: 9,
    title: "React Before After",
    img: IMG9,
    description: "A simple yet powerful React component for adding a nice before after image slider .",
    technologies: "React, Typescript",
    link: "https://react-simple-before-after.vercel.app/",
    github: "https://github.com/awran5/react-simple-before-after",
    sandbox: "https://codesandbox.io/s/react-before-after-slider-uk1ys"
  }
];

export function Portfolio() {
  return (
    <Section id="portfolio">
      <div className="section-intro text-center">
        <p className="text-light small text-space m-0">My Recent Work</p>
        <h2 className="text-primary text-space">Portfolio</h2>
      </div>

      <div className="grid-3 gap-1 portfolio-container">
        {projects.map((project) => (
          <div className="portfolio-item" key={project.id}>
            <div className="item-img">
              <img src={project.img} className="img-fluid" alt={project.title} />
              <div className="item-action">
                {project.github && (
                  <a href={project.github} target="_blank" rel="noreferrer" aria-label="github link">
                    <FaGithub />
                  </a>
                )}

                {project.link && (
                  <a href={project.link} target="_blank" rel="noreferrer" aria-label="demo link">
                    <FaLink />
                  </a>
                )}

                {project.sandbox && (
                  <a href={project.sandbox} target="_blank" rel="noreferrer" aria-label="sandbox link">
                    <FaCodepen />
                  </a>
                )}
              </div>
            </div>
            <div className="item-info">
              <p className="item-title">{project.title}</p> <span>{project.description}</span>
              <small>{project.technologies}</small>
            </div>
          </div>
        ))}
      </div>
    </Section>
  );
}
