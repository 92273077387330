import { useCallback, useEffect, useState } from "react";
import { MdHome, MdPerson, MdCode, MdVolunteerActivism, MdQuestionAnswer } from "react-icons/md";

import "./header.css";

export const navbar = [
  {
    id: "home",
    icon: <MdHome />
  },
  {
    id: "about",
    icon: <MdPerson />
  },
  {
    id: "skills",
    icon: <MdCode />
  },
  {
    id: "portfolio",
    icon: <MdVolunteerActivism />
  },
  {
    id: "contact",
    icon: <MdQuestionAnswer />
  }
];

export function Header() {
  const [activeSection, setActiveSection] = useState("home");

  useEffect(() => {
    const sectionUrl = window.location.href.split("#")[1];

    if (sectionUrl) {
      const anchor = document.getElementById(sectionUrl);
      anchor?.scrollIntoView();
      setActiveSection(sectionUrl);
    }
  }, []);

  const scrollListener = useCallback(() => {
    const { scrollTop } = document.documentElement;

    navbar.forEach((nav) => {
      const section = document.getElementById(nav.id);

      if (!section) return;

      if (scrollTop + 100 > section.offsetTop) setActiveSection(section.id);
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", scrollListener);

    return () => window.removeEventListener("scroll", scrollListener);
  }, [scrollListener]);

  return (
    <header className="header">
      <nav id="navigation" role="navigation" className="navbar">
        {navbar.map((nav) => (
          <div className="nav-item" key={nav.id}>
            <a
              href={`#${nav.id}`}
              aria-label={`go to ${nav.id}`}
              onClick={() => setActiveSection(nav.id)}
              className={activeSection === nav.id ? `nav-link active` : "nav-link"}
            >
              {nav.icon}
            </a>
            <div className="label">{nav.id}</div>
          </div>
        ))}
      </nav>
    </header>
  );
}
