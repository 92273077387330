import { Typewriter } from "react-simple-typewriter";
import { Section } from "../Section";
import Socials from "../Socials";
import "./home.css";
const CV = require("../../assets/Ahmed-AlAsttal-Resume.pdf");

export function Home() {
  return (
    <Section id="home">
      <div className="content">
        <p className="light-color">
          Hello I'm{" "}
          <span className="hello-emoji" role="img" aria-label="sheep" aria-hidden="false">
            👋
          </span>
        </p>
        <h1 className="fw-bold">Ahmed Khalil</h1>
        <p className="text-primary">
          I'm a{" "}
          <Typewriter words={["Web Developer", "UI/UX Designer", "Graphics Designer", "Freelancer!"]} loop={0} cursor />
        </p>
        <div className="home-action">
          <a href={CV} download className="btn">
            Download CV
          </a>
          <a href="#contact" className="btn btn-primary">
            Let's talk
          </a>
        </div>
        <div className="home-scroll-down">
          <a href="#about">Scroll Down</a>
        </div>
        <Socials className="home-socials" />
      </div>
    </Section>
  );
}
