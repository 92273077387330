import Socials from "../Socials";
import { navbar } from "../header/Header";
import "./footer.css";

export function Footer() {
  return (
    <footer className="footer">
      <h2 className="footer-heading fw-bold">Ahmed Khalil</h2>
      <ul className="footer-menu">
        {navbar.map((nav) => (
          <li key={nav.id}>
            <a href={`#${nav.id}`}>{nav.id}</a>
          </li>
        ))}
      </ul>
      <Socials className="footer-socials" />
      <div className="footer-copyright">
        <small>&copy; awran5 {new Date().getFullYear()}. All rights reserved.</small>
      </div>
    </footer>
  );
}
