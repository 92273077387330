interface Props {
  id: string;
  children: React.ReactNode;
}

export function Section({ id, children }: Props) {
  return (
    <section id={id} className={id}>
      <div className="container">{children}</div>
    </section>
  );
}
