import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Reaptcha from "reaptcha";
import { MdAlternateEmail } from "react-icons/md";
import { Section } from "../Section";
import "./contact.css";

export function Contact() {
  const [isSubmit, setSubmit] = useState(false);
  const [response, setResponse] = useState("");

  const formRef = useRef<HTMLFormElement>(null);
  const reaptchaRef = useRef<Reaptcha>(null);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!formRef.current) return;

    setSubmit(true);

    emailjs.sendForm("service_brqwt5g", "template_apu3v7e", formRef.current, "user_Bu6lAKjeOC5ySp6qfK8Fz").then(
      () => {
        setSubmit(false);
        setResponse("Message has been sent!");
        reaptchaRef.current?.reset();
        setTimeout(() => setResponse(""), 4000);
      },
      (error) => {
        setSubmit(false);
        setResponse("Server is busy. Please try again later.");
        console.log(error);
        reaptchaRef.current?.reset();
        setTimeout(() => setResponse(""), 4000);
      }
    );
    formRef.current.reset();
  };

  return (
    <Section id="contact">
      <div className="section-intro text-center">
        <p className="text-light small text-space m-0">Get In Touch</p>
        <h2 className="text-primary text-space">Contact Me</h2>
        <p className="lead">I do receive your messages and will respond asap if the valid email is provided :)</p>
      </div>

      <div className="contact-container">
        <div className="contact-card">
          <div className="card-item shadow">
            <MdAlternateEmail className="contact-card-icon" />
            <p className="card-title">Email</p>
            <p>awran5@yahoo.com</p>
            <a href="mailto:awran5@yahoo.com">Send a message</a>
          </div>
        </div>
        <form onSubmit={handleSubmit} ref={formRef}>
          <input type="text" placeholder="Your Full Name" name="name" required />
          <input type="text" placeholder="Your Email" name="email" required />
          <textarea placeholder="Your message" rows={7} name="message" required />
          <Reaptcha sitekey={process.env.REACT_APP_SITE_KEY} ref={reaptchaRef} />
          <button type="submit" className="btn btn-primary" disabled={isSubmit}>
            Send Message{" "}
            {isSubmit && (
              <span
                style={{
                  display: "inline-block",
                  margin: "0 25px 0 10px",
                  padding: 0
                }}
                className="loader"
              />
            )}
          </button>
          {response && (
            <div className={`alert alert-${response.includes("sent") ? "success" : "danger"}`} role="alert">
              {response}
            </div>
          )}
        </form>
      </div>
    </Section>
  );
}
