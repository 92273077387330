import { MdSchool, MdHotelClass } from "react-icons/md";
import CountUp from "react-countup";
import ME from "../../assets/myPhoto.jpg";
import { Section } from "../Section";
import "./about.css";

export function About() {
  return (
    <Section id="about">
      <div className="section-intro text-center">
        <p className="text-light small text-space m-0">Get to know</p>
        <h2 className="text-primary text-space">About Me</h2>
      </div>

      <div className="about-container">
        <div className="about-image">
          <img src={ME} alt="me" loading="lazy" />
        </div>

        <div className="about-content">
          <div className="grid-2 gap-1 cards">
            <div className="card-item shadow">
              <MdSchool className="card-icon" />
              <p className="card-title">Experience</p>
              <small className="text-light">
                <CountUp start={0} end={15} delay={3} duration={4} /> years
              </small>
            </div>
            <div className="card-item shadow">
              <MdHotelClass className="card-icon" />
              <p className="card-title">Projects</p>
              <small className="text-light">
                <CountUp start={0} end={68} delay={4} duration={5} />+ Completed Projects
              </small>
            </div>
          </div>
          <p>
            <em>Hi! I'm Ahmed I'm a full stack developer.</em>
          </p>
          <p>
            I am a freelance full-stack developer (front-end focused). I create fast, clean, and ready to scale web
            applications. Background in computer science and ability as a full-stack developer allow me to create
            everything from small business websites to custom web applications. I have over 15 years of experience in
            delivering web applications and I like making life better through technology. My work currently consists of
            freelance clients and side projects. My freelance full stack development roots are in React, Next.js,
            Gatsby, Node.js and others.
          </p>
          <p>
            Feel free to get in touch. I'm available during the week to discuss and advise on your project or
            development needs.
          </p>
          <p style={{ marginTop: 25 }}>
            <a href="#contact" className="btn btn-primary">
              Let's Talk
            </a>
          </p>
        </div>
      </div>
    </Section>
  );
}
