import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import "./quote.css";

export function Quote() {
  return (
    <section className="quote">
      <div className="container">
        <div className="quotebox">
          <span className="quote-icon">
            <FaQuoteLeft fontSize={40} />
          </span>
          <div className="quote">
            <h2>
              I'm the type of person that if you ask me a question and I don't know the answer, I'm gonna tell you that
              I don't know. But I bet you what, I know how to find the answer and I will find the answer.
            </h2>
          </div>
          <span className="quote-icon" style={{ marginLeft: "auto" }}>
            <FaQuoteRight fontSize={40} />
          </span>
          <div className="author">
            <p>
              <em>- Chris Gardner</em>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
